<template>
	<div class="hero">
		<img class="hero__logo" width="129" src="@/assets/logo.svg" alt="date69.com" />

		<div class="container">
			<div class="row">
				<div class="col-12 col-md-6 col-xl-7">
					<h1 class="hero__title">Ready to realize your innermost desire</h1>
				</div>
			</div>

			<form-signup class="hero__form" />

			<div class="hero__action d-md-none">
				<button type="button" class="custom-btn" @click="$emit('showModal')">join club</button>
			</div>
		</div>
	</div>
</template>

<script>
import FormSignup from '@/components/FormSignup.vue';

export default {
	name: 'hero',

	components: {
		'form-signup': FormSignup
	}
}
</script>

<style lang="scss">
.hero
{
	position: relative;
	display: flex;
	align-items: center;
	min-height: 100vh;
	background: url('~@/assets/hero_bg.jpg') no-repeat center;
	background-size: cover;

	&__logo
	{
		position: absolute;
		top: 30px;
		left: 15px;
		z-index: 1;
	}

	&__title
	{
		position: relative;
		text-transform: uppercase;
		font-size: 4rem;
		font-weight: 700;
		text-align: center;
		color: #FFF;

		&::before
		{
			content: '';
			position: absolute;
			top: -40%;
			left: -10%;
			width: 207px;
			height: 167px;
			background: #FD2B2B;
			mix-blend-mode: multiply;
			pointer-events: none;
			border-radius: 30px;
			transform: rotate(-38deg);
		}
	}

	&__form
	{
		display: none;
	}

	&__action
	{
		position: absolute;
		left: 15px;
		right: 15px;
		bottom: 45px;
	}

	@media (min-width: 768px)
	{
		&__logo
		{
			top: 65px;
			left: 4%;
			width: auto;
		}

		&__title
		{
			font-size: 5rem;
			text-align: left;
		}

		&__form
		{
			display: inherit;
			position: absolute;
			right: 5%;
			top: 50%;
			width: 45%;
			transform: translateY(-50%);
		}
	}

	@media (min-width: 992px)
	{
		&__title
		{
			font-size: 6rem;
		}

		&__form
		{
			width: 40%;
		}
	}

	@media (min-width: 1200px)
	{
		&__title
		{
			font-size: 7rem;

			&::before
			{
				top: -60%;
				left: -40%;
				width: 495px;
				height: 400px;
				transform: rotate(-44deg);
				border-radius: 50px;
			}
		}
	}
}
</style>
